import { Box, Typography, List, ListItem } from '@mui/material';
import {
  Icon24UtilitySearch,
  Icon24UtilityRefresh,
  Icon24InfoResources,
  Icon24InfoStar,
} from '@care/react-icons';
import getConfig from 'next/config';
import { useResponsive } from '../../hooks/useResponsive';

const {
  publicRuntimeConfig: { NEXT_ASSET_URL },
} = getConfig();

const VALUE_PROP_CONTENT = [
  {
    icon: <Icon24UtilitySearch />,
    headline: 'Effortless booking',
    subHeadline: 'Compare, choose, and book the activities you love—all with just a few clicks.',
  },
  {
    icon: <Icon24UtilityRefresh size={48} />,
    headline: 'Personalized to you',
    subHeadline:
      'Advanced search options make it easy to match programs with you and your child’s preferences.',
  },
  {
    icon: <Icon24InfoResources size={48} />,
    headline: 'Reviews you can trust',
    subHeadline:
      'With ratings and reviews from parents like you, you can feel confident in your choice.',
  },
  {
    icon: <Icon24InfoStar size={48} />,
    headline: 'All needs, one place',
    subHeadline:
      'From nannies and sitters to camps and activities, your entire childcare plan can stay here.',
  },
];

function ValueProp() {
  const { isSmallViewport } = useResponsive();
  return (
    <Box
      sx={{
        pt: {
          xs: 0,
          sm: 12,
          md: 8,
        },
        pb: {
          xs: 3,
          sm: 12,
          md: 8,
        },
        px: {
          xs: 3,
          sm: 5,
          md: 4,
        },
        display: 'flex',
        justifyContent: 'center',
        maxWidth: {
          xs: '500px',
          sm: 'none',
        },
        boxSizing: 'content-box',
        mx: 'auto',
      }}>
      <Box
        sx={{
          flexDirection: {
            xs: 'column-reverse',
            sm: 'row',
            md: 'row',
          },
          gap: {
            xs: 4,
            sm: 11,
            md: 11,
          },

          height: {
            sm: '357px',
            md: '408px',
          },
          background: 'background.default',
          width: '100%',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          maxWidth: '1062px',
        }}>
        <Box
          sx={{
            width: {
              xs: '100%',
              sm: '377px',
              md: '554px',
            },
          }}>
          <Typography
            variant="h1"
            color="textPrimary"
            sx={(theme) => ({
              mb: 2,
              lineHeight: {
                xs: '42px',
                lg: '34px',
              },

              minWidth: '237px',
              maxWidth: '300px',
              '& strong': {
                boxDecorationBreak: 'clone',
                backgroundColor: 'background.blue',
                padding: {
                  xs: '2px',
                  sm: '4px',
                  md: '4px',
                },
                borderRadius: '8px',
                lineHeight: {
                  sm: '42px',
                  md: '60px',
                },
              },

              fontSize: 'h1.fontSize',
              [theme.breakpoints.up(470)]: {
                maxWidth: 'initial',
              },
              [theme.breakpoints.up(834)]: {
                minWidth: '420px',
              },
              [theme.breakpoints.up('md')]: {
                minWidth: 'initial',
                fontSize: 'display3.fontSize',
              },
            })}>
            Easy to enjoy, <strong>easier to book</strong>
          </Typography>
          <List
            sx={{
              width: {
                sm: '450px',
                md: '100%',
              },
              mb: {
                xs: '27px',
                sm: '32px',
              },
              '> .MuiListItem-root': {
                mb: '32px',
              },
              'li:first-child p:first-child': {
                pr: {
                  xs: '6px',
                },
              },
              '& li': {
                py: '0',
              },
            }}>
            {VALUE_PROP_CONTENT.map((item) => (
              <ListItem disableGutters alignItems="flex-start" key={item.headline}>
                <Box
                  sx={{
                    flexShrink: 0,
                    mr: {
                      xs: 2,
                      sm: 4,
                    },
                    width: {
                      xs: '40px',
                      md: '48px',
                    },
                    height: {
                      xs: '40px',
                      md: '48px',
                    },
                    '& svg': {
                      width: '100%',
                      height: '100%',
                    },
                  }}>
                  {item.icon}
                </Box>
                <Box>
                  <Typography variant={!isSmallViewport ? 'h2' : 'h3'} mb={1}>
                    {item.headline}
                  </Typography>
                  <Typography variant="body2">{item.subHeadline}</Typography>
                </Box>
              </ListItem>
            ))}
          </List>
        </Box>

        <Box
          sx={{
            borderRadius: '16px',
            border: '1px solid #fff',
            overflow: 'hidden',
            width: {
              xs: '100%',
            },
            maxWidth: {
              sm: '327px',
              md: '519px',
            },
            flexBasis: {
              sm: '40%',
            },
          }}>
          <Box
            sx={{
              height: {
                xs: '277px',
                sm: '401px',
                md: '490px',
              },
              width: {
                sm: '100%',
              },
              maxWidth: '100%',
              backgroundImage: {
                xs: `url(${NEXT_ASSET_URL}/images/value-prop-mw.jpg?im=)`,
                sm: `url(${NEXT_ASSET_URL}/images/value-prop-dt.jpg?im=)`,
              },
              backgroundPosition: {
                xs: 'center',
                sm: '30%',
                md: '40%',
                lg: 'center',
              },
              backgroundRepeat: 'no-repeat',
              backgroundSize: 'cover',
              backgroundColor: {
                sm: 'background.default',
                md: '#FAF7F7',
              },
            }}
            data-testid="value-prop-image"
          />
        </Box>
      </Box>
    </Box>
  );
}

export default ValueProp;
